<template>
    <div class="bg-white">
      <div class="container mx-auto px-4 py-16">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <!-- Left Side Content -->
          <div>
            <h2 class="text-4xl font-bold leading-tight mb-4">Any questions? We got you.</h2>
            <p class="text-gray-600 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
            <button @click="openModal" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 rounded">Contact Us</button>
          </div>

          <!-- FAQ Section -->
          <div>
            <div class="border-t border-b divide-y" v-for="(faq, index) in faqs" :key="index">
              <div class="flex justify-between items-center py-4 cursor-pointer" @click="toggleFaq(index)">
                <h5 class="text-lg font-semibold">{{ faq.question }}</h5>
                <span>
                  <svg v-if="index === activeIndex" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                </span>
              </div>
              <div v-if="index === activeIndex" class="py-4">
                <p class="text-gray-500">{{ faq.answer }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script lang="ts" setup>
  import { ref } from 'vue';
  import { defineEmits } from 'vue';
  interface FAQ {
    question: string;
    answer: string;
  }


    const emit = defineEmits(['open-modal-contact']);

    const openModal = () => {
        // alert('aaaaaa')
        emit('open-modal-contact');
    };

  const activeIndex = ref<number | null>(null);

  const toggleFaq = (index: number) => {
    activeIndex.value = activeIndex.value === index ? null : index;
  };

  const faqs = ref<FAQ[]>([
    { question: 'How this work?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' },
    { question: 'This is a question?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' },
    { question: 'How can I get help?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' },
    { question: 'What features do you offer?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' },
    { question: 'Is this right?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.' },
  ]);
  </script>

  <style scoped>

  </style>
