<template>
    <div>
        <button @click="toggleChat" class="fixed bottom-16 right-4 bg-primary text-white py-2 px-4 rounded-full shadow-lg hover:bg-primary-dark focus:outline-none z-50">
            Chat with us
        </button>

        <!-- Chat UI -->
        <div v-if="isChatOpen" class="fixed bottom-16 right-4 w-80 h-96 bg-white border border-gray-300 rounded-lg shadow-lg flex flex-col z-50">
            <div class="flex justify-between items-center p-4 bg-primary text-white rounded-t-lg">
                <h3 class="text-lg font-semibold">Chat</h3>
                <button @click="toggleChat" class="text-white hover:text-gray-200">&times;</button>
            </div>
            <div class="flex-1 p-4 overflow-y-auto">
                <div v-for="message in chatMessages" :key="message.id" class="mb-2 flex" :class="message.type === 'sent' ? 'justify-end' : 'justify-start'">
                    <p :class="message.type === 'sent' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700' " class="p-2 rounded-lg max-w-xs">
                        {{ message.text }}
                    </p>
                </div>
            </div>
            <div class="p-4 border-t border-gray-300">
                <input type="text" v-model="newMessage" @keypress.enter="sendMessage" class="w-full p-2 border border-gray-300 rounded" placeholder="Type your message...">
            </div>
        </div>

        <div >
            <Hero @open-modal="openModal" @open-modal-demo="openModalDemo"  />
        </div>
        <div id="modules">
            <ModulesComponent />
        </div>
        <div id="">
            <MyTeamComponent  />
        </div>
        <div id="pricing">
            <PricingComponent @open-modal-payment="openModalPayment" />
        </div>
        <div id="faq">
            <FaqComponent  @open-modal-contact="openModalContact" />
        </div>
        <div id="testimoni">
            <TestimoniComponent />
        </div>
        <div id="agent">
            <AgentComponent @open-modal-demo="openModalDemo" />
        </div>

        <RequestDemoModal :isOpen="isModalOpen" @close="closeModal" />
        <TryDemoModal :isOpen="isModalOpenDemo" @close="closeModalDemo" />
        <PaymentModal :isUser="'no'"  :isOpen="isModalOpenPayment" @close="closeModalPayment" />
        <ContactModal :isOpen="isModalOpenContact" @close="closeModalContact" />

    </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import { getAgencyList } from '~/utils/apiService';
import { useAppStore } from '@/stores/index';
import { useAuthStore } from '@/stores/authStore';
import { useChatStore } from '@/stores/chatStore';
import RequestDemoModal from "~/components/b2c/RequestDemoModal.vue";
import TryDemoModal from "~/components/b2c/TryDemoModal.vue";
import PaymentModal from "~/components/b2c/PaymentModal.vue";
import ContactModal from "~/components/b2c/ContactModal.vue";
import { useProductStore } from '@/stores/productStore';
import { getListChat, send_message_public, historyChat } from '~/utils/apiService';
// import { messaging } from '@/utils/firebaseConfig';
import { getToken, onMessage } from "firebase/messaging";

const vapidKey = "BGUxLlfbwEf3Dr-0jrBoeHiazNgCS3bGOiOP74wKaPEyOFSUfDOg6ble7lWzXCHaKo2CQpKKhr_6Vm0LuIXe5Nw"; // Replace with your VAPID Key from the Firebase console

// if (process.client) {
//   const requestNotificationPermission = async () => {
//     try {
//       const token = await getToken(messaging, { vapidKey });
//       if (token) {
//         console.log("FCM Token:", token);
//         // You can use the token to send notifications from your server
//       } else {
//         console.log("No registration token available. Request permission to generate one.");
//       }
//     } catch (error) {
//       console.error("An error occurred while retrieving token:", error);
//     }
//   };

  // Request permission for notifications when the component mounts
//   onMounted(() => {
//     requestNotificationPermission();
//   });

//   // Handle incoming messages
//   onMessage(messaging, (payload) => {
//     console.log("Message received. ", payload);
//     // Customize your handling of the message here
//   });
// }
// Request permission on component mounted
// requestNotificationPermission();

const productStore = useProductStore();
const chatStore = useChatStore();
const chatMessages = computed(() => chatStore.getMessages);

const addMessage = (message: { id: number, text: string, type: 'sent' | 'received' }) => {
    chatStore.addMessage(message);
};

const addReply = (reply: { id: number, text: string, type: 'sent' | 'received' }) => {
    chatStore.addReply(reply);
};

const authStore = useAuthStore();
const isModalOpen = ref(false);
const isModalOpenDemo = ref(false);
const isModalOpenPayment = ref(false);
const isModalOpenContact = ref(false);
const isChatOpen = ref(false);
const newMessage = ref('');
const selectedProduct = ref({});


const openModal = () => {
    isModalOpen.value = true;
};
const openModalDemo = () => {
    isModalOpenDemo.value = true;
};

const openModalContact = () => {
    isModalOpenContact.value = true;
};
const openModalPayment = (product:any) => {
    if(authStore.isAuthenticated){
        selectedProduct.value = product
        productStore.setProduct(product);
        isModalOpenPayment.value = true;
    }else{
        showMessage('Please Login Or Register First', 'danger');
        navigateTo('/auth/cover-login')
    }
};


const closeModal = () => {
    isModalOpen.value = false;
};
const closeModalDemo = () => {
    isModalOpenDemo.value = false;
};

const closeModalPayment = () => {
    isModalOpenPayment.value = false;
};
const closeModalContact = () => {
    isModalOpenContact.value = false;
};
const toggleChat = () => {
    isChatOpen.value = !isChatOpen.value;
};

const sendMessage = async () => {
    if (newMessage.value.trim()) {
        const message = {
            id: Date.now(),
            text: newMessage.value,
            type: 'sent'
        };
        newMessage.value = '';
        const params = {
            chat_room_id: 1,
            limit: 0,
            offset: 0,
            message: message.text,
            company_id: parseInt(authStore.companyId),
            partner_id: parseInt(authStore.partnerId),
            sender_id: parseInt(authStore.userId),
            type_chat: 'general'
        };
        addMessage(message);
        const response = await send_message_public(params);



        // Simulate a reply from the system after a delay
        setTimeout(() => {
            const reply = {
                id: Date.now() + 1,
                text: response.data	,
                type: 'received'
            };
            addReply(reply);
        }, 1000);
    }
};

definePageMeta({
    layout: 'home-layout',
});

if(authStore.partnerName){
    // navigateTo('/admin')
}

const agencies = ref<any[]>([]);

const fetchAgencies = async () => {
    try {
        const params = {
            company_id: 1,
            country_id: 2,
            limit: 10,
            name: 'Example Agency',
            offset: 0,
            state_id: 3,
        };
        const response = await getAgencyList(params);
        agencies.value = response;
    } catch (error) {
        console.error('Failed to fetch agencies', error);
    }
};

// Make sure the code runs only on the client side
if (process.client) {
//   onMounted(() => {
//     // console.log("FCM Token:");
//     // Check if serviceWorker is supported
//     const requestNotificationPermission = async () => {

//         try {
//           const token = await getToken(messaging, { vapidKey });
//           if (token) {
//             console.log("FCM Token:", token);
//             // You can store the token or use it for notifications
//           } else {
//             console.log("No registration token available. Request permission to generate one.");
//           }
//         } catch (error) {
//           console.error("An error occurred while retrieving token:", error);
//         }
//       };

//       requestNotificationPermission();

//       // Handle incoming messages
//       onMessage(messaging, (payload) => {
//         console.log("Message received. ", payload);
//         // Customize handling of the message here
//       });
//   });
}
onMounted(() => {

    nextTick(() => {
        window.dispatchEvent(new Event('resize'));
    });
    fetchAgencies();
});

// revenue chart configuration...

</script>

<style scoped>
.fixed {
    position: fixed;
}
.bottom-16 {
    bottom: 4rem;
}
.right-4 {
    right: 1rem;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.hover\:bg-primary-dark:hover {
    /* background-color: primary; */
}
.focus\:outline-none:focus {
    outline: 0;
}
.z-50 {
    z-index: 50;
}
/* .text-blue-600 {
    color: #1d4ed8;
} */
.text-gray-700 {
    color: #374151;
}
</style>
