<template>
    <div class="flex justify-center bg-gray-100 py-12">
        <div class="flex  items-center space-x-8">
            <div class="flex flex-col text-left">
                <h2 class="text-gray-600">Testimonials</h2>
                <h3 class="text-2xl font-bold text-gray-800">What Our Customers Say</h3>
                <p class="mt-2 text-center text-gray-500">
                    Read their stories and experiences to see why we are the trusted choice for real estate excellence.
                </p>
                <div class="mt-4 flex space-x-4">
                    <button @click="prevTestimonial" class="rounded-full border border-red-500 p-2 text-red-500 hover:bg-red-100">←</button>
                    <button @click="nextTestimonial" class="rounded-full border border-red-500 p-2 text-red-500 hover:bg-red-100">→</button>
                </div>
            </div>
                <swiper
                    :modules="[Pagination, Autoplay]"
                    :direction="'vertical'"
                    :pagination="{ clickable: true }"
                    :autoplay="{ delay: 20000 }"
                    id="slider3"
                    :dir="store.rtlClass"
                    :key="store.rtlClass === 'rtl' ? 'true' : 'false'"
                >
                    <template v-for="(item, i) in testimonials" :key="i">
                        <swiper-slide class="flex flex-col items-center w-full">
                            <div class="max-w-md  w-full rounded-lg bg-white p-6 shadow-lg h-80">
                                <div class="mt-2 flex text-4xl text-red-500 mb-2 object-cover" style="font-size:100px;">“</div>
                                <div class="flex items-start">

                                    <p class="text-gray-700">{{ testimonial.text }}</p>
                                </div>
                                <div class="mt-4 flex items-center">
                                    <img :src="testimonial.image" alt="User" class="mr-3 h-10 w-10 rounded-full" />
                                    <div>
                                        <p class="font-bold text-gray-800">{{ testimonial.name }}</p>
                                        <div class="flex">
                                            <span v-for="star in 5" :key="star" class="text-yellow-400" :class="{ 'text-gray-300': star > testimonial.rating }"
                                                >★</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                    </template>
                </swiper>

        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ref, computed } from 'vue';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Navigation, Pagination, Autoplay } from 'swiper';
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import 'swiper/css/effect-cards';
    import { useAppStore } from '@/stores/index';
    const store = useAppStore();

    interface Testimonial {
        text: string;
        name: string;
        image: string;
        rating: number;
    }

    const testimonials = ref<Testimonial[]>([
        {
            text: "I highly recommend Jodi J. Appleby. She was attentive to our needs and worked tirelessly to find us the perfect home. We couldn't be happier with our new place!",
            name: 'Kyra Kusuma',
            image: 'https://via.placeholder.com/40',
            rating: 4,
        },
        {
            text: "I highly recommend Jodi J. Appleby. She was attentive to our needs and worked tirelessly to find us the perfect home. We couldn't be happier with our new place!",
            name: 'Kyra Kusuma',
            image: 'https://via.placeholder.com/40',
            rating: 4,
        },
        {
            text: "I highly recommend Jodi J. Appleby. She was attentive to our needs and worked tirelessly to find us the perfect home. We couldn't be happier with our new place!",
            name: 'Kyra Kusuma',
            image: 'https://via.placeholder.com/40',
            rating: 4,
        },
        {
            text: "I highly recommend Jodi J. Appleby. She was attentive to our needs and worked tirelessly to find us the perfect home. We couldn't be happier with our new place!",
            name: 'Kyra Kusuma',
            image: 'https://via.placeholder.com/40',
            rating: 4,
        },
        // Add more testimonials as needed
    ]);

    const currentTestimonialIndex = ref(0);

    const testimonial = computed(() => testimonials.value[currentTestimonialIndex.value]);

    function prevTestimonial() {
        currentTestimonialIndex.value = (currentTestimonialIndex.value - 1 + testimonials.value.length) % testimonials.value.length;
    }

    function nextTestimonial() {
        currentTestimonialIndex.value = (currentTestimonialIndex.value + 1) % testimonials.value.length;
    }
</script>

<style scoped>
    /* Custom styles if necessary */
</style>
