<template>
    <div class="pt-16 pb-16 bg-gray-100">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl text-gray-800 text-center font-semibold py-12">Features</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
          <div v-for="(feature, index) in features" :key="index" :class="{
                ' p-6 ': true,
                'bg-white rounded-3xl shadow-sm border': feature.title !== 'What We Offer'
            }">
            <div v-if="feature.title == 'What We Offer'">
                <div class="flex flex-col  space-y-4">
                    <h5 class="text-xl font-semibold">{{feature.title}}</h5>
                    <p class="text-gray-500">{{ feature.description }}</p>
                </div>
            </div>
            <div v-else>
                <div class="relative">
                <img src="/assets/images/Group.png" alt="feature icon" class="h-12 w-12 absolute top-0 left-0 " />
                <div class="pt-14">
                    <h5 class="text-xl font-semibold">{{ feature.title }}</h5>
                    <p class="text-gray-500 pt-2">{{ feature.description }}</p>
                </div>
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </template>


  <script lang="ts" setup>
  import { ref } from 'vue';

  interface Feature {
    title: string;
    description: string;
    icon: string;
  }

  const features = ref<Feature[]>([
    {
      title: 'What We Offer',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
      icon: '/assets/images/bussiness/link.png',
    },
    {
      title: 'Personal',
      description: 'Manage your personal information and settings efficiently.',
      icon: '/assets/images/bussiness/link.png',
    },
    {
      title: 'Manage Contact',
      description: 'Easily manage and organize all your contacts in one place.',
      icon: '/assets/images/financials/newspaper.png',
    },
    {
      title: 'CRM & Pipeline',
      description: 'Keep track of your sales pipeline and customer relationships.',
      icon: '/assets/images/financials/newspaper.png',
    },
    {
      title: 'Smart Action',
      description: 'Automate tasks and actions to save time and increase productivity.',
      icon: '/assets/images/financials/newspaper.png',
    },
    {
      title: 'Task To Do',
      description: 'Organize your tasks and to-dos with ease and efficiency.',
      icon: '/assets/images/financials/newspaper.png',
    },
    {
      title: 'Digital Ads',
      description: 'Create and manage digital advertising campaigns.',
      icon: '/assets/images/financials/newspaper.png',
    },
    {
      title: 'Email Blast',
      description: 'Send mass emails to your contact list effortlessly.',
      icon: '/assets/images/financials/newspaper.png',
    },
  ]);
  </script>
<style scoped>
.container {
  max-width: 1200px;
}
.border {
  border-color: #e2e8f0; /* Lighter border color */
}
.rounded-lg {
  border-radius: 20px; /* More pronounced rounded corners */
}
.shadow-sm {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /* Subtle shadow */
}
</style>
