<template>
    <div class="flex justify-center py-8 pt-36">
      <div class="flex w-3/4 items-center justify-between rounded-3xl bg-primary p-8">
        <img src="/assets/images/business man 1.png" alt="Agent" class="h-46 w-46 object-cover rounded-full -mt-36" />
        <div class="ml-8 text-white w-3/4">
          <h2 class="text-3xl font-bold">Become an Agent.</h2>
          <p class="mt-2">
            Start your journey with us today and make a difference in people's lives by helping them find their dream homes.
          </p>
        </div>
        <button @click="openModalDemo" class="ml-2 rounded-full w-1/4 bg-white px-4 py-2 font-semibold text-orange-500">
          Register Now
        </button>
      </div>
    </div>
  </template>

  <script lang="ts" setup>
    import { defineEmits } from 'vue';

    const emit = defineEmits(['open-modal', 'open-modal-demo']);

    const openModal = () => {
        emit('open-modal');
    };

    const openModalDemo = () => {
        emit('open-modal-demo');
    };
  </script>

  <style scoped>
  /* Custom styles if necessary */
  </style>
