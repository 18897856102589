<template>
    <div class="relative">
      <!-- Hero Section -->
      <div class="relative bg-cover bg-center text-white p-6 sm:p-8 rounded-2xl overflow-hidden flex flex-col justify-center items-center"
        style="background-image: url('/assets/images/header.jpg'); height: 400px;">
        <div class="absolute inset-0 bg-black opacity-50"></div>
        <h1 class="relative text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-3 text-center">
          System Solution for Real Estate Agency
        </h1>
        <p class="relative text-base sm:text-lg md:text-xl lg:text-2xl mb-4 text-center px-4 md:px-8">
          Discover modern city living with our selection of premium properties. From stylish apartments to spacious
          townhouses, we offer the perfect home to match your urban lifestyle.
        </p>
        <div class="relative flex flex-wrap justify-center">
          <NuxtLink @click="openModalDemo"
            class="bg-transparent hover:text-primary border border-white hover:bg-transparent text-white font-bold py-2 px-4 rounded mr-2 mb-2">
            Try for Free
          </NuxtLink>
          <NuxtLink @click="openModal" class="bg-primary hover:text-gray-800 text-white font-bold py-2 px-4 rounded mb-2">
            Request A Demo
          </NuxtLink>
        </div>
      </div>

      <!-- About and Map Section -->
      <div class="p-4 sm:p-8 bg-white grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h2 class="text-xl sm:text-2xl font-semibold mb-3">About</h2>
          <p class="mb-4">About Us</p>

          <p class="mb-4">
            At <span class="text-primary">Teamss.biz</span>, we're revolutionizing the real estate landscape in Indonesia.
            With a robust portfolio of over 1,000 houses available for buy and sell, we offer unparalleled options for
            finding your perfect home or investment property.
          </p>

          <p class="mb-4">
            Our platform stands out by seamlessly connecting buyers and sellers, leveraging advanced technology to
            streamline the real estate process. We pride ourselves on delivering exceptional service and expertise to help
            you navigate the complexities of property transactions with ease.
          </p>

          <p class="mb-4">
            Our mission is to make real estate transactions straightforward and efficient, backed by our commitment to
            transparency and customer satisfaction. Whether you're looking to buy, sell, or simply explore the market,
            <span class="text-primary">Teamss.biz</span> is your trusted partner in achieving your real estate goals.
          </p>

          <p class="mb-4">
            Discover the difference with <span class="text-primary">Teamss.biz</span> – where your property journey begins.
          </p>
        </div>
        <div>
          <div id="map" class="rounded-3xl w-full h-64 sm:h-72 md:h-80 lg:h-96"></div>
          <!-- Optionally, you can use an iframe for the map here if needed -->
          <!-- <iframe class="rounded-3xl w-full h-64 sm:h-72 md:h-80 lg:h-96" frameborder="0" style="border:0"
            src="https://www.google.com/maps/embed?..." allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe> -->
        </div>
      </div>
    </div>
  </template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useHead } from '#imports';
import { getPropertyPinList } from '~/utils/apiService';

const map = ref<google.maps.Map | null>(null);

const emit = defineEmits(['open-modal', 'open-modal-demo']);

const openModal = () => {
  emit('open-modal');
};

const openModalDemo = () => {
  emit('open-modal-demo');
};

useHead({
  script: [
    {
      src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDj9zgg8TYn9UbLwvPo1hs4-vyPp97tpGQ&callback=initMap`,
      async: true,
      defer: true,
    },
  ],
});

const initMap = () => {
  map.value = new google.maps.Map(document.getElementById('map') as HTMLElement, {
    center: { lat: -6.2087634, lng: 106.845599 },
    zoom: 10,
  });
  propertyPin();
};

const propertyPin = async () => {
  try {
    const params = { company_id: 1 };
    const response = await getPropertyPinList(params);
    const properties = response.list_of_property;

    properties.forEach((property: { latitude: number; longitude: number; name: string }) => {
      if (property.latitude !== 0 && property.longitude !== 0) {
        new google.maps.Marker({
          position: { lat: property.latitude, lng: property.longitude },
          map: map.value!,
          title: property.name,
        });
      }
    });
  } catch (error) {
    console.error('Failed to fetch property pins', error);
  }
};

onMounted(() => {
  if (typeof google !== 'undefined') {
    initMap();
  } else {
    window.initMap = initMap;
  }
});
</script>

  <style scoped>
/* Additional styles if needed */
</style>
