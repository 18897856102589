<template>
    <div class="pricing-container pt-5 bg-gray-100">
      <h2 class="text-3xl text-black font-bold">We’ve got a plan that’s perfect for you</h2>
      <div class="grid gap-6 xl:grid-cols-4 pt-8">
        <div
          v-for="(product, index) in products"
          :key="product.categ_id"
          class="pricing-card"
          @mouseenter="hoverState = index"
          @mouseleave="hoverState = null"
        >
          <div class="card-header">
            <h1 class="price text-3xl ml-4 py-3 px-3 text-white font-bold text-bold">
              {{ formatPrice(product.lst_price) }}
              <span class="text-lg">/{{ formatDate(product.type_subscribe) }}</span>
            </h1>
          </div>
          <div class="card-header">
            <h3 class="text-lg font-semibold pl-3">{{ product.display_name }}</h3>
          </div>

          <ul class="features pl-3">
            <li v-for="feature in product.features" :key="feature" class="text-white flex items-center space-x-2">
              <i>
                <img
                  :src="hoverState === index ? '/assets/images/check-circle-1-white.png' : '/assets/images/check-circle-1.png'"
                  alt=""
                  class="h-5 w-5 rounded-full object-cover"
                />
              </i>
              <span>{{ feature }}</span>
            </li>
          </ul>
          <button @click="openModalPayment(product)" class="choose-plan-button">CHOOSE PLAN</button>
        </div>
      </div>
    </div>
  </template>

  <script lang="ts" setup>
    import { ref, onMounted, nextTick } from 'vue';
    import { useAppStore } from '@/stores/index';
    import { getProduct } from '~/utils/apiService';
    import { formatCurrencyIDR } from '~/utils/numberFormat';
    import { defineEmits } from 'vue';

    const emit = defineEmits(['open-modal-payment']);
    const selectedProduct = ref(null);
    const openModalPayment = (product:any) => {
        // console.log(product,'product')
        // selectedProduct.value = product;
        emit('open-modal-payment', product);
    };


  const store = useAppStore();
  definePageMeta({
    layout: 'home-layout',
  });

  const products = ref([]);
  const hoverState = ref<number | null>(null); // Track hover state

  const fetchgetProduct = async () => {
    try {
      const params = {
        company_id: 1,
        limit: 0,
        name: '',
        offset: 0,
        product_id: 0,
      };
      const response = await getProduct(params);
      products.value = response.data.map((product: any) => ({
        ...product,
        features: product.list_package ? product.list_package.split('\n') : [],
      }));
    } catch (error) {
      console.error('Failed to fetch products', error);
    }
  };

  onMounted(() => {
    fetchgetProduct();
    nextTick(() => {
      window.dispatchEvent(new Event('resize'));
    });
  });
  </script>

  <style scoped>
  .pricing-container {
    text-align: center;

    padding: 40px 20px;
    border-radius: 15px;
  }

  .pricing-card {
    background-color: white;
    border: 1px solid #e0e6ed;
    padding: 0;
    border-radius: 15px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s, color 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }

  .pricing-card:hover {
    background: #FF3900;
    color: white;
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.45);
  }

  .card-header,
  .price,
  .features li {
    color: black;
  }

  .pricing-card:hover .card-header,
  .pricing-card:hover .price,
  .pricing-card:hover .features li {
    color: white;
  }

  .features {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
    flex-grow: 1;
    padding: 0 20px;
  }

  .features li {
    padding: 5px 0;
  }

  .choose-plan-button {
    background-color: #FF3900;
    color: white;
    padding: 5px 20px;
    border: none;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    align-self: center;
    width: 50%;
    text-align: center;
    margin-top: auto;
    margin-bottom: 20px;
  }

  .pricing-card:hover .choose-plan-button {
    background-color: white; /* Button background changes on pricing card hover */
    color: #333333; /* Button text color changes on pricing card hover */
    border: 1px solid #FF3900; /* Adds a border to the button on card hover */
  }

  .text-primary {
    color: #FF3900;
  }

  .pt-5 {
    padding-top: 5rem;
  }

  .pt-8 {
    padding-top: 8rem;
  }

  .grid {
    display: grid;
  }

  .gap-6 {
    gap: 1.5rem;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (max-width: 1280px) {
    .xl\:grid-cols-4 {
      grid-template-columns: 1fr;
    }
  }
  </style>
