<template>
  <section class="bg-white py-16">
    <div class="container mx-auto text-center">
      <h2 class="text-4xl font-bold mb-4">Meet Our Team Agents</h2>
      <p class="text-gray-600 mb-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
      <button class="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded mb-12">Explore More</button>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        <div v-for="member in agent_list" :key="member.id" class="text-center">
          <div class="flex justify-center items-center w-full h-full overflow-hidden">
            <img src="/assets/images/person 2.jpg" :alt="member.name" class="w-36 h-48 object-cover rounded-br-3xl rounded-tl-xl mb-4 custom-border">
          </div>
          <h3 class="text-md font-semibold">{{ member.name }}</h3>
          <p class="text-gray-500">{{ member.city}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useHead } from '#imports';
import { getAgentList } from '~/utils/apiService';

const agent_list = ref([]);

useHead({

});



const fetchAgentList = async () => {
  try {

    const params = {
        company_id: 0,
        country_id: 0,
        limit: 0,
        name: "",
        offset: 0,
        state_id: 0,
     };
    const response = await getAgentList(params);
    let index = 0;
    response.data.forEach((element:any) => {
        if(element.name != "" && element.name != "Administrator" && element.name != "Administrator (copy)" && index < 4 && element.city ){
            agent_list.value.push(element)
            index++;
        }

    });
    // agent_list.value =response.data

  } catch (error) {
    console.error('Failed to fetch property pins', error);
  }
};

onMounted(() => {
    fetchAgentList()
});
  const teamMembers = [
    {
      id: 1,
      name: 'Samuel Salim',
      title: 'Lorem ipsum dolor sit amet',
      image:'/assets/images/person 2.jpg'
    },
    {
      id: 2,
      name: 'Nathaniel Fabrian',
      title: 'Lorem ipsum dolor sit amet',
      image: '/assets/images/person 1.jpg'
    },
    {
      id: 3,
      name: 'Vanessa Berliana',
      title: 'Lorem ipsum dolor sit amet',
      image: '/assets/images/person 2.jpg'
    },
    {
      id: 4,
      name: 'Deva Andrian',
      title: 'Lorem ipsum dolor sit amet',
      image:  '/assets/images/person 1.jpg'
    }
  ];
  </script>

  <style scoped>
  .container {
    @apply max-w-7xl;
  }


  .custom-border {
    border-top-left-radius: 4rem; /* Adjust the radius as needed */
    border-bottom-right-radius: 2rem; /* Adjust the radius as needed */
    border-bottom-left-radius: 2rem; /* Adjust the radius as needed */
    border-top-right-radius: 2rem; /* Adjust the radius as needed */
  }
  </style>
